//goole map styles
var styles = [
    {
    featureType: "water",
    elementType: "geometry",
    stylers: [{
        color: "#e9e9e9"
    }, {
        lightness: 17
    }]
}, {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{
        color: "#f5f5f5"
    }, {
        lightness: 20
    }]
}, {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 17
    }]
}, {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 29
    }, {
        weight: .2
    }]
}, {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 18
    }]
}, {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{
        color: "#ffffff"
    }, {
        lightness: 16
    }]
}, {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{
        color: "#f5f5f5"
    }, {
        lightness: 21
    }]
}, {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{
        color: "#dedede"
    }, {
        lightness: 21
    }]
}, {
    elementType: "labels.text.stroke",
    stylers: [{
        visibility: "on"
    }, {
        color: "#ffffff"
    }, {
        lightness: 16
    }]
}, {
    elementType: "labels.text.fill",
    stylers: [{
        saturation: 36
    }, {
        color: "#333333"
    }, {
        lightness: 40
    }]
}, {
    elementType: "labels.icon",
    stylers: [{
        visibility: "off"
    }]
}, {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{
        color: "#f2f2f2"
    }, {
        lightness: 19
    }]
}, {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [{
        color: "#fefefe"
    }, {
        lightness: 20
    }]
}, {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{
        color: "#fefefe"
    }, {
        lightness: 17
    }, {
        weight: 1.2
    }]
}];


//goole map
function initialize(id){
    var bounds = new google.maps.LatLngBounds();
    var myOptions =
    {
        zoom: 9,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
    };

    map = new google.maps.Map(document.getElementById(id), myOptions);
    map.setOptions({styles: styles});
    if($("#" + id + "-pins").length) {
        $("#" + id + "-pins").find(".pin").each(function () {
            var latlng = new google.maps.LatLng($(this).attr("lat"), $(this).attr("lang"));
            if ( $(window).width() > 1199 ) {
                var myMarker = new google.maps.Marker(
                    {
                        position: latlng,
                        map: map,
                        icon: theme.googleMap.icon,
                    });
            }
            else {
                var myMarker = new google.maps.Marker(
                    {
                        position: latlng,
                        map: map,
                    });
            }
            bounds.extend(latlng);
        });
        map.fitBounds(bounds);
        if ( $(window).width() > 1199 ) {
            map.panBy(0, -400);
            setTimeout(function () {
                var zoom = map.getZoom();
                map.setZoom(zoom > 9 ? 9 : zoom);
            }, 500);
        }
        else {
            map.panBy(0, -100);
            setTimeout(function () {
                var zoom = map.getZoom();
                map.setZoom(zoom > 8 ? 8 : zoom);
            }, 500);
        }
    }

    google.maps.event.addDomListener(window, "resize", function() {
        map.fitBounds(bounds);
        if ( $(window).width() > 1199 ) {
            map.panBy(0, -400);
            setTimeout(function () {
                var zoom = map.getZoom();
                map.setZoom(zoom > 9 ? 9 : zoom);
            }, 500);
        }
        else {
            map.panBy(0, -100);
            setTimeout(function () {
                var zoom = map.getZoom();
                map.setZoom(zoom > 8 ? 8 : zoom);
            }, 500);
        }
    });
    return map;
}

$(window).on('load', function(){
    if ( $("div").is("#map-canvas") ){
        initialize("map-canvas");
    }
    if ( $("div").is("#map-district") ){
        var map = initialize("map-district");
        setTimeout(function () {
            var zoom = map.getZoom();
            map.setZoom(zoom < 10 ? 10 : zoom);
        }, 1000);
    }
});
